import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

const Home = ({pageContext}) => {  

  const { post }   = pageContext


  console.log(post)
  return (
    <Layout>

      <Seo
        title="Marco Gomes"
        description="Web Developer"
      />
   

      <section className="article">
        <h2>Latest Code</h2>
        <section>
          { console.log("post", post)}
        { post.map( singlePost => (
            <Link to={singlePost.uri} key={singlePost.id}>
                <div className="article__item">
                  <div>
                    { singlePost.featuredImage && 
                    <GatsbyImage image={ getImage( singlePost.featuredImage.node.imageFile )} alt={singlePost.featuredImage.altText}  className="article__item__img"/>
                    }
                  </div>
                  <div>
                    <h2 className="article__item__title"  dangerouslySetInnerHTML={{ __html: singlePost.title }}></h2>
                    
                  </div>
                </div>
            </Link> 
            ))
        }
     
        
        </section>
      </section>


   
    </Layout>
  )
}

export default Home